/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; /* eslint-disable import/first */
import type { GatsbyBrowser } from "gatsby";

/* export { wrapPageElement, wrapRootElement } from "./gatsby-shared";

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  return window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(
      /\bno-js\b/,
      "js"
    );
    initializeToggleMenu("__nm-script", "#toggle", "#menu");
  });
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  initializeToggleMenu("__nm-script", "#toggle", "#menu");
};
 */
// export { wrapPageElement } from "./gatsby-shared";

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  return window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(
      /\bno-js\b/,
      "js"
    );
    initToggleMenu("__nm-script", "#toggle", "#menu");
  });
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  initToggleMenu("__nm-script", "#toggle", "#menu");
};
